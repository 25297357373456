import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  BulkDeleteButton,
} from "react-admin";
import UsersEdit from "./UsersEdit";
const BulkActionButtons: React.FunctionComponent = (props) => (
  <React.Fragment>
    <BulkDeleteButton disabled={true} />
  </React.Fragment>
);

const UserList: React.FunctionComponent = (props) => {
  return (
    <List {...props} bulkActionButtons={<BulkActionButtons />}>
      <Datagrid>
        <TextField source='id' />
        <TextField source='username' />
        <EmailField source='email' />
        <EditButton LinkComponent={UsersEdit} />
      </Datagrid>
    </List>
  );
};

export default UserList;
